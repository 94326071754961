<script>
export default {
  name: 'AdminUploads',
  components: {
    DataQualityUpload: () => import('../components/DataQualityUpload.vue'),
  },
  props: [],
  data() {
    return {
      uploadTypes: [
        { name: 'Analysis BCFZip', value: 'bcfzip', disabled: true },
        { name: 'Data Quality CSVs', value: 'dataquality' },
        { name: 'Issues CSV', value: 'issues', disabled: true },
      ],
      selectedUploadType: 'dataquality',
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters.selectedProject;
    },
    projectNameDisplay() {
      const mode = [2];
      const project = this.selectedProject[0];

      const code = mode.includes(0) ? project.code : '',
        key = mode.includes(1) ? project.projectId : '',
        name = mode.includes(2) ? project.name : '';

      let displayName = [code ? code : '', key ? key : '', name ? name : ''];

      if (mode.length === 0) {
        displayName = [project.name];
      }

      return displayName.filter(p => p).join(' ');
    },
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>

<template>
  <div>
    <div v-if="selectedProject[0]" class="text-h6 px-3 pt-3">
      {{ selectedProject[0] && projectNameDisplay }}
    </div>
    <div class="px-3 pt-3">
      <v-select
        v-model="selectedUploadType"
        label="Upload Type"
        item-text="name"
        item-value="value"
        :items="uploadTypes"
      />
      <v-divider />
      <div v-if="selectedUploadType === 'dataquality'">
        <DataQualityUpload />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
